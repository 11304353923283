import HttpUtils, {ApiResponse} from '../utils/HttpUtils'
import {IAccountInfo, IQueryAccountList} from "@/api/types";
import {LIMIT_DEFAULT, OFFSET_DEFAULT} from "@/store/config";

const AccountApi = {
    async list(data: IQueryAccountList) {
        const params: IQueryAccountList = {
            offset: data.offset ? data.offset : OFFSET_DEFAULT,
            limit: data.limit ? data.limit : LIMIT_DEFAULT,
            accountType: data.accountType ? data.accountType : 1
        }
        return (await HttpUtils.post('kr_account_list', params)) as ApiResponse<IAccountInfo[]>;
    },

    async getAccountByAddress(address: string) {
        const params: { address: string } = {
            address: address
        }
        return (await HttpUtils.post('kr_account_address', params)) as ApiResponse<IAccountInfo>;
    }
}

export default AccountApi;
