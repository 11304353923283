import React, {useState, useEffect, ReactNode} from "react";
import {useNavigate, useLocation} from 'react-router-dom'
import AccountApi from '../api/AccountApi'
import type {ColumnsType} from 'antd/es/table';
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMinViewTable.css'
import '../common/css/ArkreenTable.css'
import '../common/css/ArkreenMain.css'
import {formatLongString, formatVisualizationDate, formatAccountTypeIcon} from '@/utils/DataFormatUtils'
import ArkreenTable from '../components/ArkreenTable'
import {AccountType} from "@/store/types";
import {TOKEN_NAME} from "@/store/config";

interface IAccount {
    key: string
    address: ReactNode
    earningMint: ReactNode
    amountMiner: ReactNode
    accountType: ReactNode
    createTime: ReactNode
}

function Accounts() {

    const navigate = useNavigate()
    const location = useLocation()
    const columns: ColumnsType<AccountType> = [
        {title: 'Address', dataIndex: 'address', key: 'address', width: '180px', fixed: 'left',},
        {title: 'Reward(' + TOKEN_NAME + ')', dataIndex: 'earningMint', key: 'earningMint', width: '180px'},
        {title: 'Miners', dataIndex: 'amountMiner', key: 'amountMiner', width: '100px',},
        {title: 'Type', dataIndex: 'accountType', key: 'accountType', width: '120px'},
        {title: 'Age', dataIndex: 'createTime', key: 'createTime', width: '180px'},
    ];
    const utm = new URLSearchParams(location.search)
    const [accounts, setAccounts] = useState<IAccount[]>()
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(utm.get("page") ? Number(utm.get("page")) : 1)

    function showAccountDetail(address: string) {
        navigate('/account/' + address)
    }

    async function handleAccounts() {
        setLoading(true)
        const resp = await AccountApi.list({offset: currentPage})
        const dataList: IAccount[] = []
        resp.result?.forEach((item) => {
            const data: IAccount = {
                key: item.address,
                address: <span onClick={() => {
                    showAccountDetail(item.address)
                }} className="content-td-link">{formatLongString(item.address, 7, 7)}</span>,
                earningMint: <span>{Number(Number(item.rewardBalance).toFixed(2)).toLocaleString() + ''}</span>,
                amountMiner: <span>{item.minerCount}</span>,
                accountType: <span>{formatAccountTypeIcon(Number(item.accountType))}</span>,
                createTime: <span>{formatVisualizationDate(item.createTime)}</span>,
            }
            dataList.push(data)
        })
        setAccounts(dataList)
        setLoading(false)
    }

    useEffect(() => {
        handleAccounts();
    }, [currentPage])//eslint-disable-line

    return (
        <div className="arkreen-main">
            <div className="arkreen-main-title">
                <span>Accounts</span>
            </div>
            <div className="arkreen-main-content">
                <ArkreenTable scroll={{x: 760}} currentPage={currentPage} columns={columns} datalist={accounts}
                              loading={loading} onPageChange={setCurrentPage}/>
            </div>
        </div>
    );
}

export default Accounts;
