import {useState, useEffect} from "react";
import ArkreenMinCard from '@/components/ArkreenMinCard';
import ArkreenGrowthTrendCard from '@/components/ArkreenGrowthTrendCard';
import {Col, Row} from 'antd';
import {
    MinerLineIcon,
    AccountLineIcon
} from '@/icons/ArkreenIcon'
import StatApi from '../api/StatApi'
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMain.css'
import moment from 'moment'
import {INetworkInfo, IQueryDeviceDaily} from "@/api/types";

export interface IDeviceDailyToday {
    miner: string
    time: string
}

export interface IDeviceRewardDailyToday {
    reward: string
    time: string
}

export interface IGrowth {
    date: string
    value: number
}

export interface IMetaCfg {
    value: {
        min: number
        max: number
    }
}

function Dashboard() {

    const [networkInfo, setNetworkInfo] = useState<INetworkInfo>()
    const [minerGrowthData, setMinerGrowthData] = useState<IGrowth[]>();
    const [minerRewardGrowthData, setMinerRewardGrowthData] = useState<IGrowth[]>();
    const [deviceDaily, setDeviceDaily] = useState<IDeviceDailyToday>();
    const [deviceRewardDaily, setDeviceRewardDaily] = useState<IDeviceRewardDailyToday>();
    const [minerGrowthChartMetaCfg, setMinerGrowthChartMetaCfg] = useState<IMetaCfg>()

    function stateNetworkTrend() {
        StatApi.stateNetworkTrend().then((rsp) => {
            setNetworkInfo(rsp.result)
        })
    }

    function handleStatDeviceDaily() {
        const startDay = moment().add(-60,'days').utc(true).format("YYYY-MM-DD")
        const endDay = moment().utc(true).format("YYYY-MM-DD")
        const params: IQueryDeviceDaily = {
            startDay: startDay,
            endDay: endDay,
            limit: 25,
            offset: 1
        }
        StatApi.statDeviceDaily(params).then((rsp) => {
            const MinerGrowth: IGrowth[] = []
            const MinerRewardGrowth: IGrowth[] = []
            const deviceDaily: IDeviceDailyToday = {miner: '0', time: ''}
            const deviceRewardDaily: IDeviceRewardDailyToday = {reward: '0', time: ''}
            if (rsp.result) {
                for (let index = rsp.result.length - 1; index >= 0; index--) {
                    const item = rsp.result[index];
                    MinerGrowth.push({date: item.dataDate, value: item.miners});
                    MinerRewardGrowth.push({date: item.dataDate, value: Number(item.totalReward)});
                    deviceDaily.miner = Number(item.miners).toLocaleString()
                    deviceRewardDaily.reward = Number(item.totalReward).toLocaleString()
                    const timeArr = moment(item.dataDate).format('LLLL').split(",");
                    deviceDaily.time = timeArr[0] + "," + timeArr[1]
                    deviceRewardDaily.time = timeArr[0] + "," + timeArr[1]
                }
            }
            const slice = -14;
            const tempMinerGrowthData = MinerGrowth.slice(slice)
            setMinerGrowthData(MinerGrowth.slice(slice));
            setMinerRewardGrowthData(MinerRewardGrowth.slice(slice));
            setDeviceDaily(deviceDaily);
            setDeviceRewardDaily(deviceRewardDaily);
            //
            tempMinerGrowthData.sort(function (a, b) {
                return a.value - b.value
            })
            const minMinerGrowthData = tempMinerGrowthData[0].value
            const maxMinerGrowthData = tempMinerGrowthData[tempMinerGrowthData.length - 1].value
            const minerGrowthInterval = (maxMinerGrowthData - minMinerGrowthData) / 2
            setMinerGrowthChartMetaCfg({
                value: {
                    min: minMinerGrowthData - minerGrowthInterval,
                    max: maxMinerGrowthData + minerGrowthInterval
                }
            })
        })
    }

    useEffect(() => {
        stateNetworkTrend();
        handleStatDeviceDaily();
    }, [])

    return (
        <div className="arkreen-main">
            <Row gutter={[20, 20]}>
                <Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}><ArkreenMinCard
                    title='Accounts' icon={<AccountLineIcon/>}
                    content={networkInfo ? Number(networkInfo.owners ? networkInfo.owners : 0).toLocaleString() : ""}/></Col>
                <Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                    <ArkreenMinCard title='Miners' icon={<MinerLineIcon/>}
                                    content={networkInfo ? networkInfo.miners.toLocaleString() : ''}/>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                    <ArkreenMinCard title='Rewards' icon={<MinerLineIcon/>}
                                    content={networkInfo ? Number(Number(networkInfo.totalReward ? networkInfo.totalReward : 0).toFixed(3)).toLocaleString() : ''}/>
                </Col>
            </Row>
            <Row gutter={[20, 20]} style={{paddingTop: '20px'}}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <ArkreenGrowthTrendCard fullButton={true} routerLink='/stat/miner' type={2} title='Miner Growth'
                                            metaCfg={minerGrowthChartMetaCfg}
                                            value={deviceDaily ? deviceDaily.miner : 0}
                                            time={deviceDaily ? deviceDaily.time : 0} data={minerGrowthData}/>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <ArkreenGrowthTrendCard fullButton={true} routerLink='/stat/miner' type={2} title='Rewards Growth'
                                            metaCfg={minerRewardGrowthData}
                                            value={deviceRewardDaily ? deviceRewardDaily.reward : 0}
                                            time={deviceRewardDaily ? deviceRewardDaily.time : 0} data={minerRewardGrowthData}/>
                </Col>
            </Row>
        </div>
    );
}

export default Dashboard;
