import React, {useState} from 'react';
import {Layout, Input, Space, Divider, message, Image} from 'antd';
import ArkreenButton from '@/components/ArkreenButton';
import './css/PageTop.css'
import {
    SearchIcon,
    MenuIcon,
    AppLogoSingleIcon,
    ArkreenLogoTextUnionIcon,
    CloseMenuIcon,
    ArkreenHomeIcon,
    ArkreenConsoleIcon,
    ArkreenDocsIcon, AppLogoIcon,
} from '@/icons/ArkreenIcon'
import {useNavigate} from 'react-router-dom'
import StatApi from '../api/StatApi'
import {getMenuList, jumpTo} from '@/utils/BrowserUtils'

const {Header} = Layout;

function AppTopBar() {

    const search_style: React.CSSProperties = {
        display: 'none'
    };

    function showMenu() {
        const style: React.CSSProperties = {
            position: 'absolute',
            top: '64px',
            background: '#FFFFFF',
            width: '100%',
            height: '-webkit-fill-available',
            color: '#000000',
            zIndex: '999',
            display: menuStyle.display === 'none' ? 'block' : 'none',
        };
        setMenuStyle(style)
    }

    const menu_style: React.CSSProperties = {
        position: 'absolute',
        top: '64px',
        background: '#FFFFFF',
        width: '100%',
        height: '-webkit-fill-available',
        color: '#000000',
        zIndex: '999',
        display: 'none'
    };

    const [menuStyle, setMenuStyle] = useState(menu_style)
    const [searchStyle, setSearchStyle] = useState(search_style)
    const [searchValue, setSearchValue] = useState<any>(undefined)
    const navigate = useNavigate()

    function gotoHome() {
        if (menuStyle.display !== 'none') {
            showMenu();
        }
        navigate('/')
    }

    function itemOnClick(key: string, url: string) {
        navigate(url)
        showMenu();
    }

    let isSearch = false

    function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.code === 'Enter') {
            if (!isSearch) {
                search(searchValue.target.value)
                closeSearch()
            }
        }
    }

    async function search(value: string) {
        isSearch = true;
        try {
            const rsp: any = await StatApi.search(value);
            if (!rsp.error) {
                message.success("jump to result page");
                const type = rsp.result.type;
                if (type === 0) {
                    navigate('/tx/' + value)
                } else if (type === 1) {
                    navigate('/block/' + value)
                } else if (type === 2) {
                    navigate('/account/' + value)
                } else if (type === 3) {
                    navigate('/miner/' + value)
                } else if (type === 4) {
                    navigate('/block/' + rsp.result.data.height)
                }
            } else {
                message.error(rsp.error.message);
            }
        } catch (err) {
            message.error("search error,please try again");
        }
        isSearch = false;
    }

    function showSearch() {
        const style: React.CSSProperties = {
            display: 'block'
        };
        setSearchStyle(style)
    }

    function closeSearch() {
        const style: React.CSSProperties = {
            display: 'none'
        };
        setSearchStyle(style)
    }

    return (
        <Header className='headerStyle'>
            <div className='top-menu-box-m'>
                <Space>
                    <div onClick={() => gotoHome()} style={{paddingLeft: '3px'}}>
                        <Image src={`/images/icon/logo-icon.png`} alt='logo' height={32} width={49} />
                    </div>
                </Space>
                <Space>
                    <div style={{gap: '0px'}}>
                        <ArkreenButton type='link' className='top-search-open' onClick={showSearch}><span><SearchIcon/></span></ArkreenButton>
                        <ArkreenButton type='link' className='top-menu-open'
                                       onClick={showMenu}><span>{menuStyle.display === 'none' ? <MenuIcon/> :
                            <CloseMenuIcon/>}</span></ArkreenButton>
                    </div>
                </Space>
            </div>
            <div className='top-menu-box-pc'>
                <Space>
                    <Input onChange={setSearchValue} onKeyDown={e => onKeyDown(e)}
                           prefix={<SearchIcon style={{color: '#B7B7BC'}}/>} allowClear className='top-search-box'
                           placeholder="Search for an address..."/>
                </Space>
            </div>
            <div className='top-menu-show'>
                <div style={menuStyle}>
                    <div className='mobile-menu-box'>
                        {
                            getMenuList().map((item) => {
                                return <div key={item.key} onClick={() => itemOnClick(item.key, item.url)}>
                                    <div className='mobile-menu-icon'>{item.icon}</div>
                                    <div className='mobile-menu-name'>{item.label}</div>
                                </div>
                            })
                        }
                        <Divider/>
                        <div onClick={() => jumpTo('' + process.env.NEXT_PUBLIC_HOME_LINK)}>
                            <div className='mobile-menu-icon'><ArkreenHomeIcon/></div>
                            <div className='mobile-menu-name'>Home</div>
                        </div>
                        <div onClick={() => jumpTo('' + process.env.REACT_APP_CONSOLE_URL)}>
                            <div className='mobile-menu-icon'><ArkreenConsoleIcon/></div>
                            <div className='mobile-menu-name'>Console</div>
                        </div>
                        <div onClick={() => jumpTo('' + process.env.NEXT_PUBLIC_DOCS_LINK)}>
                            <div className='mobile-menu-icon'><ArkreenDocsIcon/></div>
                            <div className='mobile-menu-name'>Docs</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={searchStyle}>
                <div className='top-search-show'>
                    <div style={{float: 'left', marginTop: '-4px', flex: 1}}>
                        <Input onChange={setSearchValue} onKeyDown={e => onKeyDown(e)}
                               prefix={<SearchIcon style={{color: '#B7B7BC'}}/>} allowClear className='top-search-box-m'
                               placeholder="Search for an address..."/>
                    </div>
                    <div style={{float: 'right', lineHeight: '64px'}}>
                        <ArkreenButton type='link' className='top-menu-open'
                                       onClick={closeSearch}><span><CloseMenuIcon/></span></ArkreenButton>
                    </div>
                </div>
            </div>
        </Header>
    );
}

export default AppTopBar;
