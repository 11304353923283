import HttpUtils, {ApiResponse} from '../utils/HttpUtils'
import {IQueryReportList, IQueryRewardList, IQueryTxList, IReportInfo, IRewardInfo, ITx} from "@/api/types";
import {LIMIT_DEFAULT, OFFSET_DEFAULT} from "@/store/config";

async function getTransactionList(data: IQueryTxList) {
    const params: IQueryTxList = {
        txType: data.txType,
        offset: data.offset ? data.offset : OFFSET_DEFAULT,
        limit: data.limit ? data.limit : LIMIT_DEFAULT,
        hash: data.hash,
        address: data.address
    }
    return (await HttpUtils.post('kr_transaction_list', params)) as ApiResponse<ITx[]>;
}

async function getTransactionByHash(hash: string) {
    return (await HttpUtils.post('kr_transaction_hash', {hash: hash})) as ApiResponse<ITx>;
}

async function getMinerRewardList(data: IQueryRewardList) {
    const params = {
        address: data.address,
        offset: data?.offset ? data.offset : OFFSET_DEFAULT,
        limit: data?.limit ? data.limit : LIMIT_DEFAULT,
    }
    return (await HttpUtils.post('kr_reward_history', params)) as ApiResponse<IRewardInfo[]>;
}

//TODO 返回类型未定义
async function getTransactionDataByHash(hash: string) {
    return await HttpUtils.post('kr_transaction_data', {hash: hash});
}

async function getReportList(data: IQueryReportList) {
    const params = {
        minerAddress: data.minerAddress,
        offset: data?.offset ? data.offset : OFFSET_DEFAULT,
        limit: data?.limit ? data.limit : LIMIT_DEFAULT,
    }
    return (await HttpUtils.post('kr_report_list', params)) as ApiResponse<IReportInfo[]>;
}

const TransactionApi = {
    getTransactionList: getTransactionList,
    getTransactionByHash: getTransactionByHash,
    getTransactionDataByHash: getTransactionDataByHash,
    getMinerRewardList: getMinerRewardList,
    getReportList: getReportList
}

export default TransactionApi;