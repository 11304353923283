import { message } from 'antd';
import React, { useEffect, useState } from 'react'
import {
    AccountLineIcon,
    //BlockLineIcon,
    DashboardLineIcon,
    //GalleryLineIcon,
    MinerLineIcon,
    //TransactionLineIcon
} from "@/icons/ArkreenIcon";

export function copyText(text:string){
    const input = document.createElement('input')
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("Copy");
    document.body.removeChild(input);
    message.success('Copied!');
}

interface WindowSize {
    width: number
    height: number
}

export function useWindowSize(): WindowSize {
    const [windowSize, setWindowSize] = useState<WindowSize>({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  
    useEffect(() => {
      const handler = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }
  
      // Set size at the first client-side load
      handler()
  
      window.addEventListener('resize', handler)
  
      // Remove event listener on cleanup
      return () => {
        window.removeEventListener('resize', handler)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
    return windowSize
}

export function jumpTo(url:string,target?:string){
  const domA = document.createElement('a')
  domA.href = url
  domA.target = target?target:'_blank'
  domA.click()
}

export interface IMenuItem {
    key: string
    sort: string
    icon: React.ReactNode | JSX.Element
    label: string
    url: string
}

export function getMenuList(){
    const menuList:IMenuItem[] = [];
    const dashboard = {
        key: '',
        sort: '1',
        icon: <DashboardLineIcon/>,
        label: 'Dashboard',
        url: '/'
    }
    const accounts = {
        key: 'accounts',
        sort: '2',
        icon: <AccountLineIcon/>,
        label: 'Accounts',
        url: '/accounts'
    }
    const miners = {
        key: 'miners',
        sort: '3',
        icon: <MinerLineIcon/>,
        label: 'Miners',
        url: '/miners'
    }
    /*
    const transactions = {
        key: 'transactions',
        sort: '4',
        icon: <TransactionLineIcon />,
        label: 'Transactions',
        url: '/txs'
    }
    const blocks = {
        key: 'blocks',
        sort: '5',
        icon: <BlockLineIcon/>,
        label: 'Blocks',
        url: '/blocks',
    }
    const cspGallery = {
        key: 'csp-gallery',
        sort: '5',
        icon: <GalleryLineIcon/>,
        label: 'CSP Gallery',
        url: '/csp-gallery',
    }
    */
    menuList.push(dashboard)
    menuList.push(accounts)
    menuList.push(miners)
    //menuList.push(transactions)
    //menuList.push(blocks)
    //menuList.push(cspGallery)
    return menuList
}
