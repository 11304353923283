import React from 'react';
import {GitHubIcon,DiscordIcon,TelegramIcon,TwitterIcon} from '@/icons/ArkreenIcon'

function AppFooterBar(){

  const footerStyle: React.CSSProperties = {
    textAlign: 'right',
    backgroundColor:'#FFFFFF',
    color:'#000000',
    width:'100%',
    paddingInlineEnd:'20px',
    lineHeight:'48px',
    height:'48px',
    marginTop: '-48px',
  };

  return (
    <div style={footerStyle}>
      <span style={{display:'none'}}>2022-2023 Arkreen Network. All Rights Reserved. info@arkreen.com</span>
      <a style={{fontSize:'1.2em',color:'#00913A'}} href={`${process.env.REACT_APP_GITHUB_URL}`} target='_blank' rel="noreferrer"><GitHubIcon /></a>
      <a style={{fontSize:'1.2em',paddingLeft:'20px',color:'#00913A'}} href={`${process.env.REACT_APP_DISCORD_URL}`} target='_blank' rel="noreferrer"><DiscordIcon /></a>
      <a style={{fontSize:'1.2em',paddingLeft:'20px',color:'#00913A'}} href={`${process.env.REACT_APP_TWITTER_URL}`} target='_blank' rel="noreferrer"><TwitterIcon /></a>
      <a style={{fontSize:'1.2em',paddingLeft:'20px',color:'#00913A'}} href={`${process.env.REACT_APP_TG_URL}`} target='_blank' rel="noreferrer"><TelegramIcon /></a>
    </div>
  );
}

export default AppFooterBar;
