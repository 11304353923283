import {Tabs, Tooltip} from 'antd';
import React, {useState, useEffect} from "react";
import MinerApi from '../api/MinerApi'
import TransactionApi from '../api/TransactionApi'
import StatApi from '../api/StatApi'
import type {ColumnsType} from 'antd/es/table';
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMinViewTable.css'
import '../common/css/DetailPage.css'
import '../common/css/ArkreenMain.css'
import '../common/css/ArkreenSelect.css'
import {
    formatLongString,
    formatVisualizationDate,
    formatMinerTypeTag,
    formatDate,
    formatPowerUnit2,
    formatElectricity2,
} from '@/utils/DataFormatUtils'
import ArkreenTable from '../components/ArkreenTable'
import {useLocation} from 'react-router-dom'
import {
    RewardIcon,
    OwnerIcon,
    OnboardingTimeIcon,
    ExpiredTimeIcon,
    TypeIcon,
} from '@/icons/ArkreenIcon'
import {useNavigate} from 'react-router-dom'
import type {TabsProps} from 'antd';
import moment from 'moment'
import ArkreenDetailTd from '../components/ArkreenDetailTd'
import ArkreenInfoHeaderCard from '../components/ArkreenInfoHeaderCard'
import ArkreenStackAreaChart from '../components/ArkreenStackAreaChart'
import {TOKEN_NAME} from "@/store/config";
import {IMinerInfo, IQueryMinerDaily} from "@/api/types";

interface IDataType {
    power: number;
    total: string;
    height: number;
    hash: string;
    timestamp: number;
    epoch: number;
}

interface IReport {
    key: string;
    createTime: React.ReactNode;
    energy: React.ReactNode;
    power: React.ReactNode;
    totalEnergy: React.ReactNode;
}

interface IReward {
    key: string;
    amount: React.ReactNode;
    timestamp: React.ReactNode;
}

interface IStat {
    dataDate: string;
    value: number;
    seriesField: string;
}

function MinerDetail() {

    const location = useLocation()
    const address = location.pathname.replace('/miner/', '')
    const navigate = useNavigate()
    const [minerAddress, setMinerAddress] = useState(address)
    const [data, setData] = useState<IStat[]>();
    const [reportList, setReportList] = useState<IReport[]>([])
    const [rewardsList, setRewardsList] = useState<IReward[]>([]);
    const utm = new URLSearchParams(location.search)
    const [loadingTx, setLoadingTx] = useState(true)
    const [txType, setTxType] = useState(utm.get("type") ? Number(utm.get("type")) : 'report')
    const [currentPageTx, setCurrentPageTx] = useState(utm.get("page") ? Number(utm.get("page")) : 1)
    const [loadingReward, setLoadingReward] = useState(true)
    const [currentPageReward, setCurrentPageReward] = useState(utm.get("page") ? Number(utm.get("page")) : 1)
    const [growthDisplay, setGrowthDisplay] = useState('block')
    const [minerInfo, setMinerInfo] = useState<IMinerInfo>()

    const columnsTx: ColumnsType<IDataType> = [
        {
            title: 'Power(W)',
            dataIndex: 'power',
            key: 'power',
            width: '120px',
        },
        {
            title: 'energy(kWh)',
            dataIndex: 'energy',
            key: 'energy',
            width: '120px',
        },
        {
            title: 'Total Energy(kWh)',
            dataIndex: 'totalEnergy',
            key: 'totalEnergy',
            width: '160px',
        },
        {
            title: 'Age',
            dataIndex: 'createTime',
            key: 'createTime',
            width: '180px',
        },
    ];

    const columnsReward: ColumnsType<IDataType> = [
        {
            title: 'Reward(' + TOKEN_NAME + ')',
            dataIndex: 'amount',
            key: 'amount',
            width: '120px',
        },
        {
            title: 'Age',
            dataIndex: 'timestamp',
            key: 'timestamp',
            width: '180px',
        },
    ];

    const items: TabsProps['items'] = [
        {
            key: 'report',
            label: 'Report',
            children: <ArkreenTable currentPage={currentPageTx} columns={columnsTx}
                                    datalist={reportList} loading={loadingTx} onPageChange={setCurrentPageTx}/>,
        },
        {
            key: 'reward',
            label: 'Reward',
            children: <ArkreenTable currentPage={currentPageReward} columns={columnsReward}
                                    datalist={rewardsList} loading={loadingReward}
                                    onPageChange={setCurrentPageReward}/>,
        }
    ];

    async function getReportList() {
        const data = {
            minerAddress: minerAddress,
            offset: currentPageTx,
        }
        setLoadingTx(true)
        const rsp = await TransactionApi.getReportList(data)
        const reportArr: IReport[] = []
        rsp.result?.forEach((item, index) => {
            const report: IReport = {
                key: item.minerAddress + '_' + index,
                createTime: <span>{formatVisualizationDate(Number(item.createTime))}</span>,
                power: <span>{formatPowerUnit2(Number(item.metrics.power) / 1000)}</span>,
                energy: <span>{formatElectricity2(Number(item.metrics.energy) / 1000)}</span>,
                totalEnergy: <span>{formatElectricity2(Number(item.metrics.totalEnergy) / 1000)}</span>,
            }
            reportArr.push(report)
        })
        setReportList(reportArr)
        setLoadingTx(false)
    }

    async function getRewards() {
        const data = {
            address: minerAddress,
            offset: currentPageReward,
            limit: 25,
        }
        setLoadingReward(true)
        const rsp = await TransactionApi.getMinerRewardList(data);
        const RewardsList: IReward[] = [];
        rsp.result?.forEach((item) => {
            const reward = {
                key: item.id,
                amount: <span>{Number(item.amount).toLocaleString() + ""}</span>,
                timestamp: <span>{formatVisualizationDate(item.createTime)}</span>
            }
            RewardsList.push(reward)
        })
        setRewardsList(RewardsList)
        setLoadingReward(false)
    }

    async function stat() {
        const startTime = formatDate(Number(minerInfo!.onboardTime), 'yyyy-MM-dd');
        const endTime = formatDate(Date.now(), 'yyyy-MM-dd');
        const data: IQueryMinerDaily = {
            minerAddress: minerAddress,
            startDay: startTime!,
            endDay: endTime!,
        }
        const deviceDaily = await StatApi.statDeviceDailyByMiner(data);
        if (deviceDaily.error || (deviceDaily.result && deviceDaily.result.length <= 0)) {
            setGrowthDisplay('none');
        } else {
            const dateArr: IStat[] = []
            if (deviceDaily.result) {
                for (let i = deviceDaily.result.length - 1; i >= 0; i--) {
                    const day = deviceDaily.result[i].dataDate;
                    const dayStr = moment(day).format('yyyy-MM-DD')
                    let reward = 0
                    deviceDaily.result?.forEach(item => {
                        // eslint-disable-next-line eqeqeq
                        if (item.dataDate == dayStr) {
                            reward = Number(item.totalReward)
                        }
                    })
                    dateArr.push({dataDate: dayStr, value: reward, seriesField: 'Reward'})
                }
            }
            setData(dateArr)
        }
    }

    function getMinerByAddress() {
        setMinerAddress(address)
        MinerApi.getMinerByAddress(address).then((resp) => {
            if (resp.result) {
                setMinerInfo(resp.result)
            } else {
                navigate("/404")
            }
        });
    }

    function showOwnerDetail(address: string) {
        navigate('/account/' + address)
    }

    const onChange = (key: string) => {
        setTxType(key)
        let data = new URLSearchParams(location.search);
        data.set('type', "" + key);
        data.set('page', "1");
        setCurrentPageTx(1);
        setCurrentPageReward(1);
        navigate(`?` + data.toString(), {state: {fromHome: true}})
    };

    useEffect(() => {
        getMinerByAddress()
    }, [address])//eslint-disable-line

    useEffect(() => {
        if (minerInfo?.minerAddress) {
            getReportList();
        }
    }, [currentPageTx])//eslint-disable-line

    useEffect(() => {
        if (minerInfo?.minerAddress) {
            getRewards();
        }
    }, [currentPageReward])//eslint-disable-line

    useEffect(() => {
        if (minerAddress && minerInfo?.minerAddress) {
            getReportList();
            getRewards();
            stat();
        }
    }, [minerInfo?.minerAddress])//eslint-disable-line

    return (
        <div className='arkreen-main'>
            <div className='detail-header'>
                <ArkreenInfoHeaderCard title='Miner' value={minerAddress}/>
                <div className='detail-header-info-box'>
                    <div className='detail-header-info-box-left'>
                        <ArkreenDetailTd icon={<TypeIcon style={{width: '14.33px', height: '14.33px'}}/>} title="Type"
                                         content={minerInfo ? formatMinerTypeTag(minerInfo.minerType) : ''}/>
                        <ArkreenDetailTd icon={<RewardIcon style={{width: '14.33px', height: '14.33px'}}/>}
                                         title="Total Reward"
                                         content={minerInfo ? (Number(Number(minerInfo.rewardBalance).toFixed(2)).toLocaleString() + " " + TOKEN_NAME) : ''}/>
                        <ArkreenDetailTd icon={<OwnerIcon style={{width: '14.33px', height: '14.33px'}}/>} title="Owner"
                                         content={minerInfo ? <span onClick={() => {
                                             showOwnerDetail("" + minerInfo.ownerAddress)
                                         }}
                                                                    className="content-td-link">{formatLongString("" + minerInfo.ownerAddress, 7, 7)}</span> : ''}/>
                        <ArkreenDetailTd icon={<OnboardingTimeIcon style={{width: '14.33px', height: '14.33px'}}/>}
                                         title="Onboarding Time" content={minerInfo ? (
                            <Tooltip placement="right"
                                     title={formatDate(minerInfo.onboardTime ? minerInfo.onboardTime : 0, 'yyyy-MM-dd HH:mm:ss')}>{formatVisualizationDate(Number(minerInfo.onboardTime))}</Tooltip>) : ''}/>
                        {
                            minerInfo &&
                            <ArkreenDetailTd icon={<ExpiredTimeIcon style={{width: '14.33px', height: '14.33px'}}/>}
                                             title="Expired Time"
                                             content={formatVisualizationDate(Number(minerInfo.onboardTime + (minerInfo.expiredTime * 1000)))}/>
                        }
                    </div>
                </div>
            </div>
            <div style={{display: growthDisplay}} className='detail-table-box-tx detail-table-box'>
                <ArkreenStackAreaChart height='192px' title='Reward' data={data} xField='dataDate' yField='value'
                                       seriesField='seriesField' lineColor={['#1EAAF9', '#00913A']}/>
            </div>
            <div className='detail-table-box'>
                <Tabs style={{marginTop: '-12px'}} defaultActiveKey={"" + txType} items={items} onChange={onChange}/>
            </div>
        </div>
    );
}

export default MinerDetail;