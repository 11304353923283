import {Tabs, Tooltip} from 'antd';
import React, {useState, useEffect} from "react";
import AccountApi from '../api/AccountApi'
import StatApi from '../api/StatApi'
import MinerApi from '../api/MinerApi'
import type {ColumnsType} from 'antd/es/table';
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMinViewTable.css'
import '../common/css/DetailPage.css'
import '../common/css/ArkreenMain.css'
import '../common/css/ArkreenSelect.css'
import {
    formatLongString,
    formatVisualizationDate,
    formatMinerTypeTag,
    formatStatusIcon,
    formatDate,
} from '@/utils/DataFormatUtils'
import ArkreenTable from '../components/ArkreenTable'
import ArkreenDetailTd from '../components/ArkreenDetailTd'
import type {TabsProps} from 'antd';
import {useNavigate, useLocation} from 'react-router-dom'
import {RewardIcon, MinerLineIcon, TimestampIcon} from '@/icons/ArkreenIcon'
import moment from 'moment'
import ArkreenInfoHeaderCard from '../components/ArkreenInfoHeaderCard'
import ArkreenStackAreaChart from '../components/ArkreenStackAreaChart'
import {getDates} from '@/utils/DateUtils'
import {TOKEN_NAME} from "@/store/config";
import {IAccountInfo, IQueryOwnerDaily} from "@/api/types";

interface IDataType {
    address: string;
    nonce: number;
    accountType: number;
    earningMint: string;
    earningService: string;
    withdrawAmount: string;
    amountMiner: number;
}

interface IMiner {
    key: string
    address: React.ReactNode
    minerType: React.ReactNode
    totalReward: React.ReactNode
    onboardTime: React.ReactNode
    status: React.ReactNode
}

function AccountDetail() {

    const location = useLocation()
    const navigate = useNavigate()
    const accountAddress = location.pathname.replace('/account/', '')
    const [minerList, setMinerList] = useState<IMiner[]>()
    const utm = new URLSearchParams(location.search)
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(utm.get("page") ? Number(utm.get("page")) : 1)
    const [growthDisplay, setGrowthDisplay] = useState('block')
    const [data, setData] = useState<any>([]);
    const [userInfo, setUserInfo] = useState<IAccountInfo>()

    const items: TabsProps['items'] = [{key: 'miners', label: 'Miners'}];

    const columns: ColumnsType<IDataType> = [
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: '180px',
            fixed: 'left',
        },
        {
            title: 'Type',
            dataIndex: 'minerType',
            key: 'minerType',
            width: '160px'
        },
        {
            title: 'Reward(' + TOKEN_NAME + ')',
            dataIndex: 'totalReward',
            key: 'totalReward',
            width: '180px'
        },
        {
            title: 'Onboarding Time',
            dataIndex: 'onboardTime',
            key: 'onboardTime',
            width: '180px'
        },
    ];

    function showMinerDetail(address: string) {
        navigate('/miner/' + address)
    }

    async function getMiners() {
        const data = {
            ownerAddress: accountAddress,
            offset: currentPage,
        }
        setLoading(true)
        const dataList: IMiner[] = []
        const rsp = await MinerApi.list(data);
        rsp.result?.forEach((item) => {
            let status = formatStatusIcon('success', 'Normal');
            if (item.status === 2) {
                status = formatStatusIcon('success', 'Normal')
            } else if (item.status === 3) {
                status = formatStatusIcon('danger', 'Terminated')
            } else {
                status = formatStatusIcon('warning', 'Abnormal')
            }
            const data: IMiner = {
                key: item.minerAddress,
                address: <span onClick={() => {
                    showMinerDetail(item.minerAddress)
                }} className="content-td-link">{formatLongString(item.minerAddress, 7, 7)}</span>,
                minerType: <span>{formatMinerTypeTag(item.minerType)}</span>,
                totalReward: <span>{Number(item.rewardBalance).toLocaleString()}</span>,
                onboardTime: <span>{formatVisualizationDate(item.onboardTime)}</span>,
                status: status,
            }
            dataList.push(data)
        })
        setMinerList(dataList)
        setLoading(false)
    }

    async function getAccountInfo() {
        const rsp = await AccountApi.getAccountByAddress(accountAddress);
        if (rsp.result) {
            setUserInfo(rsp.result)
        } else {
            navigate("/404")
        }
    }

    async function stat() {
        if (userInfo) {
            const startTime = formatDate(userInfo.createTime, 'yyyy-MM-dd');
            const endTime = moment.utc().format('yyyy-MM-DD')
            const data: IQueryOwnerDaily = {
                ownerAddress: userInfo.address,
                startDay: startTime!,
                endDay: endTime,
            }
            const days = getDates(new Date(userInfo.createTime), new Date())
            const dailyReward = await StatApi.statOwnerDailyReward(data);
            if (dailyReward.error || (dailyReward.result && dailyReward.result.length <= 0)) {
                setGrowthDisplay('none');
            } else {
                const dateArr: any = []
                const hideTime = moment.utc().format('yyyy-MM-DD')
                for (let i = 0; i < days.length; i++) {
                    const day = days[i];
                    const dayStr = moment(day).format('yyyy-MM-DD')
                    if (hideTime !== dayStr) {
                        let reward = 0;
                        dailyReward.result?.forEach(item => {
                            // eslint-disable-next-line eqeqeq
                            if (item.dataDate == dayStr) {
                                reward = Number(item.totalReward)
                            }
                        })
                        dateArr.push({dataDate: dayStr, value: reward, seriesField: ' Reward'})
                    }
                }
                console.log(dateArr)
                setData(dateArr)
            }
        }
    }

    useEffect(() => {
        if (userInfo?.createTime) {
            stat()
        }
    }, [userInfo])//eslint-disable-line

    useEffect(() => {
        getMiners()
    }, [accountAddress, currentPage])//eslint-disable-line

    useEffect(() => {
        getAccountInfo()
    }, [location.pathname])//eslint-disable-line

    return (
        <div className='arkreen-main'>
            <div className='detail-header'>
                <ArkreenInfoHeaderCard title='Account' value={accountAddress}/>
                <div style={{paddingBlockStart: '20px'}}>
                    <ArkreenDetailTd icon={<RewardIcon style={{width: '14.33px', height: '14.33px'}}/>}
                                     title="Total Reward"
                                     content={(userInfo?.withdrawnBalance && userInfo.rewardBalance) ? (Number(Number(Number(userInfo.rewardBalance) + Number(userInfo.withdrawnBalance)).toFixed(2)).toLocaleString() + " " + TOKEN_NAME) : ''}/>
                    <ArkreenDetailTd icon={<MinerLineIcon style={{width: '14.33px', height: '14.33px'}}/>}
                                     title="Miners" content={userInfo?.minerCount}/>
                    <ArkreenDetailTd icon={<TimestampIcon style={{width: '14.33px', height: '14.33px'}}/>} title="Age"
                                     content={userInfo?.createTime ? <Tooltip placement="right"
                                                                              title={formatDate(userInfo.createTime ? userInfo.createTime : 0, 'yyyy-MM-dd HH:mm:ss')}>{formatVisualizationDate(Number(userInfo.createTime))}</Tooltip> : ''}/>
                </div>
            </div>
            <div style={{display: growthDisplay}} className='detail-table-box-tx detail-table-box'>
                <ArkreenStackAreaChart height='192px' title='Cumulative Rewards' data={data} xField='dataDate'
                                       yField='value' seriesField='seriesField' lineColor={['#00913A']}/>
            </div>
            <div className='detail-table-box'>
                <Tabs style={{marginTop: '-12px'}} defaultActiveKey="1" items={items}/>
                <ArkreenTable pageSize={Number(userInfo?.minerCount)} currentPage={currentPage}
                              columns={columns} datalist={minerList} loading={loading} onPageChange={setCurrentPage}/>
            </div>
        </div>
    );
}

export default AccountDetail;