import {useState, useEffect} from "react";
import {useLocation, useNavigate} from 'react-router-dom'
import {Col, Row} from 'antd';
import StatApi from '../api/StatApi'
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMain.css'
import moment from 'moment'
import {LeftIcon} from '@/icons/ArkreenIcon'
import ArkreenStatGrowthChart from "@/components/ArkreenStatGrowthChart";
import {IQueryDeviceDaily} from "@/api/types";
import {IDeviceDailyToday, IGrowth} from "@/routes/Dashboard";

function StatGrowth() {
    //
    const height = '77vh'
    const location = useLocation()
    const navigate = useNavigate()
    const type = location.pathname.replace('/stat/', '')
    const [minerGrowthData, setMinerGrowthData] = useState<IGrowth[]>();
    const [deviceDaily, setDeviceDaily] = useState<IDeviceDailyToday>();

    //
    function handleStatDeviceDaily() {
        const params: IQueryDeviceDaily = {
            startDay: "2024-09-01",
            endDay: "2024-09-09",
            limit: 25,
            offset: 1
        }
        StatApi.statDeviceDaily(params).then((rsp) => {
            if (!rsp.error) {
                const MinerGrowth: any[] = []
                const deviceDaily: IDeviceDailyToday = {miner: '0', time: ''}
                if (rsp.result) {
                    for (let index = rsp.result.length - 1; index >= 0; index--) {
                        const item: any = rsp.result[index];
                        MinerGrowth.push({date: item.dataDate, value: item.minerTotal});
                        deviceDaily.miner = Number(item.minerTotal).toLocaleString()
                        const timeArr = moment(item.dataDate).format('LLLL').split(",");
                        deviceDaily.time = timeArr[0] + "," + timeArr[1]
                    }
                }
                setMinerGrowthData(MinerGrowth);
                setDeviceDaily(deviceDaily);
            }
        })
    }

    useEffect(() => {
        handleStatDeviceDaily();
    }, [])


    return (
        <div className="arkreen-main">
            <Row gutter={[16, 16]} style={{paddingTop: '0'}}>
                <Col className="gutter-row" span={24}>
                    <div className="back-btn">
                        <div onClick={() => navigate('/')} style={{fontSize: '20px'}}><LeftIcon/></div>
                        <div onClick={() => navigate('/')} style={{lineHeight: '24px'}}> Back</div>
                    </div>
                </Col>
                <Col className="gutter-row" span={24}>
                    {deviceDaily && type === 'miner' &&
                        <ArkreenStatGrowthChart height={height} title='Miner Growth' value={deviceDaily.miner}
                                                time={deviceDaily.time} data={minerGrowthData}/>}
                </Col>
            </Row>
        </div>
    );
}

export default StatGrowth;
