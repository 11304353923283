import HttpUtils, {ApiResponse} from '../utils/HttpUtils'
import {
    IDeviceDaily, IMinerDaily,
    INetworkInfo,
    IOwnerDaily,
    IQueryDeviceDaily,
    IQueryMinerDaily,
    IQueryOwnerDaily
} from "@/api/types";

async function stateNetworkTrend() {
    return (await HttpUtils.post('kr_stat_network', {})) as ApiResponse<INetworkInfo>;
}

async function statDeviceDaily(params: IQueryDeviceDaily) {
    return (await HttpUtils.post('kr_stat_network_daily', params)) as ApiResponse<IDeviceDaily[]>;
}

async function statDeviceDailyByMiner(data: IQueryMinerDaily) {
    const params:IQueryMinerDaily = {
        minerAddress: data.minerAddress,
        endDay: data.endDay,
        startDay: data.startDay
    }
    return (await HttpUtils.post('kr_stat_miner_daily', params)) as ApiResponse<IMinerDaily[]>;
}

async function statOwnerDailyReward(data: IQueryOwnerDaily) {
    const params:IQueryOwnerDaily = {
        ownerAddress: data.ownerAddress,
        endDay: data.endDay,
        startDay: data.startDay
    }
    return (await HttpUtils.post('kr_stat_owner_daily', params)) as ApiResponse<IOwnerDaily[]>;
}

//TODO 后端接口未定义
async function search(criteria: string) {
    const params = {
        "criteria": criteria
    }
    return await HttpUtils.post('net_search', params);
}


const StatApi = {
    stateNetworkTrend: stateNetworkTrend,
    statOwnerDailyReward: statOwnerDailyReward,
    statDeviceDaily: statDeviceDaily,
    search: search,
    statDeviceDailyByMiner: statDeviceDailyByMiner,
}

export default StatApi;