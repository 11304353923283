import {Select} from 'antd';
import React, {useState, useEffect} from "react";
import {useNavigate, useLocation} from 'react-router-dom'
import MinerApi from '../api/MinerApi'
import type {ColumnsType} from 'antd/es/table';
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMinViewTable.css'
import '../common/css/ArkreenSelect.css'
import '../common/css/ArkreenMain.css'
import '../common/css/ArkreenTable.css'
import {formatLongString, formatVisualizationDate, formatStatusIcon, formatMinerTypeTag} from '@/utils/DataFormatUtils'
import ArkreenTable from '../components/ArkreenTable'
import {TOKEN_NAME} from "@/store/config";

interface IDataType {
    address: string;
    minerType: number;
    runStatus: number;
    status: number;
    ratedPower: string;
    totalEnergyGeneration: number;
    withdrawAmount: string;
    onboardTime: number;
    maker: string;
    earningMint: string;
}

interface IMiner {
    key: string
    address: React.ReactNode
    minerType: React.ReactNode
    totalReward: React.ReactNode
    onboardTime: React.ReactNode
    status: React.ReactNode
}

function Miners() {
    const location = useLocation()
    const navigate = useNavigate()

    function showMinerDetail(address: string) {
        navigate('/miner/' + address)
    }

    const columns: ColumnsType<IDataType> = [
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: '140px',
            fixed: 'left',
        },
        {
            title: 'Type',
            dataIndex: 'minerType',
            key: 'minerType',
            width: '120px'
        },
        {
            title: 'Reward(' + TOKEN_NAME + ')',
            dataIndex: 'totalReward',
            key: 'totalReward',
            width: '120px'
        },
        {
            title: 'Onboarding Time',
            dataIndex: 'onboardTime',
            key: 'onboardTime',
            width: '140px'
        },
    ];

    const [minerList, setMinerList] = useState<IMiner[]>([])
    const [loading, setLoading] = useState(true)
    const utm = new URLSearchParams(location.search)
    const [currentPage, setCurrentPage] = useState(utm.get("page") ? Number(utm.get("page")) : 1)
    const [minerType, setMinerType] = useState(utm.get("type") ? Number(utm.get("type")) : undefined)
    const [lifecycle, setLifecycle] = useState(utm.get("lifecycle") ? Number(utm.get("lifecycle")) : undefined)

    async function getMiners() {
        const data = {
            offset: currentPage,
            type: minerType,
            status: lifecycle,
        }
        setLoading(true)
        const rsp = await MinerApi.list(data);
        const minerList: IMiner[] = []
        rsp.result?.forEach((item) => {
            let status
            if (item.status === 2) {
                status = formatStatusIcon('success', 'Normal')
            } else if (item.status === 3) {
                status = formatStatusIcon('danger', 'Terminated')
            } else {
                status = formatStatusIcon('warning', 'Abnormal')
            }
            const miner: IMiner = {
                key: item.minerAddress,
                address: <span onClick={() => {
                    showMinerDetail(item.minerAddress)
                }} className="content-td-link">{formatLongString(item.minerAddress, 7, 7)}</span>,
                minerType: <span>{formatMinerTypeTag(Number(item.minerType))}</span>,
                totalReward: <span>{Number(Number(item.rewardBalance).toFixed(2)).toLocaleString()}</span>,
                onboardTime: <span>{formatVisualizationDate(item.onboardTime)}</span>,
                status: status,
            }
            minerList.push(miner)
        })
        setMinerList(minerList)
        setLoading(false)
    }

    function handleMinerTypeChange(value: string) {
        let data = new URLSearchParams(location.search);
        if (!value) {
            data.delete('type')
        } else {
            data.set('type', value)
        }
        data.set('page', "1")
        navigate("?" + data.toString());
        setMinerType(Number(value));
        setCurrentPage(1)
    }

    function handleLifecycleChange(value: string) {
        let data = new URLSearchParams(location.search);
        if (!value) {
            data.delete('lifecycle')
        } else {
            data.set('lifecycle', value)
        }
        data.set('page', "1")
        navigate("?" + data.toString());
        setLifecycle(Number(value));
        setCurrentPage(1)
    }

    useEffect(() => {
        getMiners();
    }, [currentPage, minerType, lifecycle])//eslint-disable-line

    return (
        <div className="arkreen-main">
            <div className="arkreen-main-title">
                <span>Miners</span>
            </div>
            <div className="arkreen-main-content">
                <div className='arkreen-select-group'>
                    <div className='arkreen-select-item arkreen-select-padding-right'>
                        <span className='arkreen-select-title'> Miner Type </span>
                        <Select
                            allowClear={true}
                            className='arkreen-select'
                            defaultValue={minerType ? "" + minerType : null}
                            onChange={handleMinerTypeChange}
                            placeholder='Miner Type'
                            options={[
                                {value: '1', label: 'Gaming Miner'},
                                {value: '2', label: 'Remote Miner'},
                                {value: '5', label: 'Standard Miner'},
                            ]}/>
                    </div>
                    <div className='arkreen-select-item arkreen-select-padding-left'>
                        <span className='arkreen-select-title'> Lifecycle </span>
                        <Select
                            allowClear={true}
                            defaultValue={lifecycle ? "" + lifecycle : null}
                            placeholder='Lifecycle'
                            className='arkreen-select'
                            onChange={handleLifecycleChange}
                            options={[
                                {value: '0', label: 'Active'},
                                {value: '3', label: 'Terminated'},
                                {value: '4', label: 'Expired'}
                            ]}/>
                    </div>
                </div>
                <ArkreenTable currentPage={currentPage} columns={columns} datalist={minerList}
                              loading={loading} onPageChange={setCurrentPage}/>
            </div>
        </div>
    );
}

export default Miners;