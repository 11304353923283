console.log("use api:"+process.env.REACT_APP_BASE_API)

const JSONRPC = "2.0";
const POST = "POST";
const JSON_HEADERS = {
    "Content-Type": "application/json",
}

const API_BASE_URL = process.env.REACT_APP_BASE_API+'/v1'

export interface ApiResponse<T> {
    jsonrpc: string
    id: number
    result?: T
    error?: {
        code: number
        message: string
    }
}

async function post<T, U>(method: string, data: U): Promise<ApiResponse<T>> {
    const params = {
        jsonrpc: JSONRPC,
        id: Number(Math.random().toString().substring(2)),
        method: method,
        params: data
    };
    const res = await fetch(API_BASE_URL + "?m=" + method, {
        method: POST,
        headers: JSON_HEADERS,
        body: JSON.stringify(params),
    });
    return (await res.json()) as ApiResponse<T>;
}

const HttpUtils = {
    post:post
}

export default HttpUtils
