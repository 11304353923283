import React, {useState, useEffect} from "react";
import {useNavigate, useLocation} from 'react-router-dom'
import '../common/css/ArkreenMenu.css'
import {IMenuItem} from "@/utils/BrowserUtils";

type Props = {
    menuList: IMenuItem[],
    selectKey?: string,
    style?: React.CSSProperties,
    collapsed: boolean,
};

export default function ArkreenMenu(prop: Props): JSX.Element {
    const navigate = useNavigate()
    const location = useLocation();
    const [selectKey, setSelectKey] = useState(prop.selectKey)
    const [collapsed, setCollapsed] = useState(prop.collapsed)

    function itemOnClick(key: string, url: string) {
        setSelectKey(key);
        navigate(url)
    }

    useEffect(() => {
        const base_title = ' - Arkreen Explorer';
        let pathname = location.pathname
        if (pathname.startsWith("/")) {
            pathname = pathname.replace("/", '')
            document.title = `Dashboard` + base_title;
        }
        if (pathname.startsWith('account')) {
            if (pathname.startsWith('accounts')) {
                document.title = `Accounts` + base_title;
            } else {
                document.title = `Account ` + pathname.replace("account/", '') + base_title;
            }
            pathname = 'accounts'
        } else if (pathname.startsWith('block')) {
            if (pathname.startsWith('blocks')) {
                document.title = `Blocks` + base_title;
            } else {
                document.title = `Block ` + pathname.replace("block/", '') + base_title;
            }
            pathname = 'blocks'
        } else if (pathname.startsWith('tx')) {
            if (pathname.startsWith('txs')) {
                document.title = `Transactions` + base_title;
            } else {
                document.title = `Transaction ` + pathname.replace("tx/", '') + base_title;
            }
            pathname = 'txs'
        } else if (pathname.startsWith('miner')) {
            if (pathname.startsWith('miners')) {
                document.title = `Miners` + base_title;
            } else {
                document.title = `Miner ` + pathname.replace("miner/", '') + base_title;
            }
            pathname = 'miners'
        } else if (pathname.startsWith('stat')) {
            document.title = `Growth` + base_title;
            if (pathname.endsWith('miner')) {
                document.title = `Miner Growth` + base_title;
            } else if (pathname.endsWith('capacity')) {
                document.title = `PV Capacity Growth` + base_title;
            } else if (pathname.endsWith('Energy')) {
                document.title = `Green Energy Data Uploaded Growth` + base_title;
            } else if (pathname.endsWith('capacity')) {
                document.title = `Impact Growth` + base_title;
            }
            pathname = 'stat'
        } else if (pathname.startsWith('csp-gallery')) {
            document.title = `CSP Gallery` + base_title;
            pathname = 'csp-gallery'
        } else {
            if (pathname !== '') {
                navigate('/404')
            }
        }
        setSelectKey(pathname)

    }, [location.pathname])//eslint-disable-line

    useEffect(() => {
        setCollapsed(prop.collapsed)
    }, [prop.collapsed])

    return (
        <div style={prop.style}>
            <ul className={collapsed ? 'menu-main-collapsed' : 'menu-main'}>
                {
                    prop.menuList.map((item, index) => <li key={`menu_${index}_${item.key}`}
                                                           onClick={() => itemOnClick(item.key, item.url)}
                                                           className={collapsed ? (selectKey === item.key ? 'menu-item menu-item-collapsed menu-item-active' : 'menu-item menu-item-collapsed') : (selectKey === item.key ? 'menu-item menu-item-active' : 'menu-item')}>
                        <span
                            className={selectKey === item.key ? 'menu-item-logo menu-item-logo-active' : 'menu-item-logo'}>{item.icon}</span>
                        <span className={collapsed ? 'menu-item-span-collapsed' : ''}>{item.label}</span>
                    </li>)
                }
            </ul>
        </div>

    )
}
    