import HttpUtils, {ApiResponse} from '../utils/HttpUtils'
import {IMinerInfo, IQueryMinerList} from "@/api/types";
import {LIMIT_DEFAULT, OFFSET_DEFAULT} from "@/store/config";

async function list(data:IQueryMinerList) {
    const params= {
        offset:data.offset?data.offset:OFFSET_DEFAULT,
        limit:data.limit?data.limit:LIMIT_DEFAULT,
        minerAddress:data.minerAddress,
        ownerAddress:data.ownerAddress
    }
    return (await HttpUtils.post('kr_miner_list',params)) as ApiResponse<IMinerInfo[]>;
}

async function getMinerByAddress(address:string) {
    return (await HttpUtils.post('kr_miner_address',{minerAddress:address})) as ApiResponse<IMinerInfo>;
}

const MinerApi ={
    list:list,
    getMinerByAddress:getMinerByAddress,
}

export default MinerApi;