import React, {useState, useEffect} from 'react';
import {Layout, Row, Col,} from 'antd';
import {
    AppLogoSingleIcon,
    ArkreenHomeIcon,
    ArkreenConsoleIcon,
    ArkreenDocsIcon,
    ArkreenMenuSwitchLeftIcon,
    ArkreenMenuSwitchRightIcon, AppLogoIcon,
} from '@/icons/ArkreenIcon'
import ArkreenMenu from '../components/ArkreenMenu'
import {useLocation, useNavigate} from 'react-router-dom'
import {useWindowSize, jumpTo, getMenuList} from '@/utils/BrowserUtils'
import '../common/css/ArkreenMenu.css'

const {Sider} = Layout;

function AppSiderBar() {

    const location = useLocation();
    const [getSelectKey] = useState(location.pathname.substring(1))
    const [collapsed, setCollapsed] = useState(false)
    const [userControl, setUserControl] = useState(false)

    const navigate = useNavigate()

    function gotoHome() {
        navigate('/')
    }

    function siderChange() {
        setUserControl(true);
        setCollapsed(!collapsed)
    }

    const windowSize = useWindowSize();

    useEffect(() => {
        if (!userControl) {
            (windowSize.width) > 992 ? setCollapsed(false) : setCollapsed(true)
        }
    }, [windowSize.width])//eslint-disable-line

    return (
        <Row>
            <Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Sider breakpoint='lg' width="240px" className='menu-box' collapsed={collapsed}>
                    <div onClick={() => gotoHome()}>
                        <div className={collapsed ? 'hide' : 'logo-long'}>
                            <AppLogoIcon/>
                        </div>
                        <div className={collapsed ? 'logo-short' : 'hide'}><AppLogoSingleIcon/></div>
                    </div>
                    <ArkreenMenu selectKey={getSelectKey} menuList={getMenuList()} collapsed={collapsed}/>
                    <div className={collapsed ? 'footer-link-main-collapsed' : 'footer-link-main'}>
                        <ul style={{listStyle: 'none', padding: '0px'}}>
                            <li className={collapsed ? 'footer-link-item-center' : 'footer-link-item-left'}
                                onClick={() => jumpTo(''+process.env.NEXT_PUBLIC_HOME_LINK)}>
                                <span className={collapsed ? 'footer-link-logo-collapsed' : 'footer-link-logo'}><ArkreenHomeIcon/></span>
                                <span className={collapsed ? 'hide' : 'footer-link-span'}>Home</span>
                            </li>
                            <li className={collapsed ? 'footer-link-item-center' : 'footer-link-item-left'}
                                onClick={() => jumpTo('' + process.env.REACT_APP_CONSOLE_URL)}>
                                <span className={collapsed ? 'footer-link-logo-collapsed' : 'footer-link-logo'}><ArkreenConsoleIcon/></span>
                                <span className={collapsed ? 'hide' : 'footer-link-span'}>Console</span>
                            </li>
                            <li className={collapsed ? 'footer-link-item-center' : 'footer-link-item-left'}
                                onClick={() => jumpTo('' + process.env.NEXT_PUBLIC_DOCS_LINK)}>
                                <span className={collapsed ? 'footer-link-logo-collapsed' : 'footer-link-logo'}><ArkreenDocsIcon/></span>
                                <span className={collapsed ? 'hide' : 'footer-link-span'}>Docs</span>
                            </li>
                        </ul>
                        <div className={collapsed ? 'hide' : 'menu-switch-btn-left'}>
                            <div onClick={() => siderChange()}><ArkreenMenuSwitchLeftIcon/></div>
                        </div>
                        <div className={collapsed ? 'menu-switch-btn-right' : 'hide'}>
                            <div onClick={() => siderChange()}><ArkreenMenuSwitchRightIcon/></div>
                        </div>
                    </div>
                </Sider>
            </Col>
        </Row>
    );
}

export default AppSiderBar;
